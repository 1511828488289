<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    if (this.isMobile()) {
      this.$router.push("/mobile/home");
    } else {
      this.$router.push("/pc/home");
    }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style scoped></style>
